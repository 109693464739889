export const menuItems = [
    {
        id: 1,
        label: 'Dashboard',
        isTitle: true,
    },
    {
        id: 2,
        label: 'Overview',
        icon: 'ri-dashboard-line',
        link: '/',
    },
    {
        id: 3,
        label: 'Pharmacy',
        isTitle: true,
    },
    {
        id: 7,
        label: 'Orders',
        icon: 'ri-shopping-cart-line',
        link: '/pharmacy/orders',
    },
    {
        id: 9,
        label: 'Prescription mgnt',
        icon: 'ri-parking-box-line',
        link: '/prescriptions',
    },
    {
        id: 10,
        label: 'Drugs mgnt',
        icon: 'ri-medium-line',
        link: '/pharmacy',
    },
    {
        id: 11,
        label: 'Expired Drugs',
        icon: 'ri-spam-2-line',
        link: '/pharmacy/expired-drugs',
    },
    {
        id: 14,
        label: 'Reviews',
        icon: 'ri-medal-2-fill',
        link: '/reviews',
    },
    {
        id: 15,
        label: 'Groceries',
        isTitle: true,
    },
    {
        id: 16,
        label: 'Orders',
        icon: 'ri-shopping-cart-line',
        link: '/orders',
    },
    {
        id: 18,
        label: 'Recipe Orders',
        icon: 'ri-shopping-basket-2-line',
        link: '/recipe',
    },
    {
        id: 19,
        label: 'Groceries mgnt',
        icon: 'ri-shopping-basket-2-line',
        link: '/groceries',
    },
    {
        id: 19,
        label: 'Recipe mgnt',
        icon: 'ri-shopping-basket-2-line',
        link: '/recipe',
    },
    {
        id: 19,
        label: 'Expired groceries',
        icon: 'ri-spam-2-line',
        link: '/groceries',
    },
    {
        id: 8,
        label: 'Reviews',
        icon: 'ri-medal-2-fill',
        link: '/reviews',
    },
    {
        id: 24,
        label: 'Setups',
        isTitle: true,
    },
    {
        id: 26,
        label: 'Categories',
        icon: 'ri-folder-2-line',
        link: '/categories',
    },
    // {
    //     id: 17,
    //     label: "Products",
    //     icon: "ri-product-hunt-line",
    //     link: '/products'
    // },
    {
        id: 12,
        label: 'Doctors',
        icon: 'ri-map-pin-user-line',
        link: '/doctors',
    },
    {
        id: 13,
        label: 'Patients',
        icon: 'ri-file-user-line',
        link: '/patients',
    },
    {
        id: 17,
        label: 'Riders',
        icon: 'ri-folder-user-line',
        link: '/riders',
    },
    {
        id: 27,
        label: 'Suppliers',
        icon: 'ri-team-line',
        link: '/suppliers',
    },
    {
        id: 28,
        label: 'Brands',
        icon: 'ri-drag-move-2-line',
        link: '/brands',
    },
    {
        id: 29,
        label: 'Branches',
        icon: 'ri-home-5-line',
        link: '/branches',
    },
    {
        id: 30,
        label: 'Coupons',
        icon: 'ri-coupon-4-line',
        link: '/coupons',
    },
    {
        id: 32,
        label: 'Users',
        icon: 'ri-shield-user-line',
        link: '/users/getall',
    },
    {
        id: 33,
        label: 'General Settings',
        icon: 'ri-settings-2-line',
        link: '/settings',
    },
    {
        id: 35,
        label: 'Transactions',
        isTitle: true,
    },
    {
        id: 36,
        label: 'Finance',
        icon: 'ri-refund-2-line',
        link: '/apps/chat',
    },
    {
        id: 38,
        label: 'Invoices',
        icon: 'ri-refund-2-line',
        link: '/apps/chat',
    },
];
