<script>
/**
 * Footer Component
 */
export default {}
</script>

<template>
<!-- Footer Start -->
<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
               <span class="text-dark"> {{ new Date().getFullYear()}} &copy; by</span> <a href="">Pharm & Organic Convenience</a>
            </div>
            <div class="col-md-6">
                
            </div>
        </div>
    </div>
</footer>
<!-- end Footer -->
</template>
